import Tipe81 from "./8 (1).webp";
import Tipe82 from "./8cl.webp";
import Tipe83 from "./8 (2).webp";
import Tipe84 from "./8nobel.webp";
import Tipe85 from "./8 (3).webp";
import Tipe86 from "./8royal.webp";

const tipe8images = [Tipe81, Tipe82, Tipe83, Tipe84, Tipe85, Tipe86];

export default tipe8images;
