import React from "react";
import "./lokasi.scss";

const lokasi = () => {
  return (
    <div className="container-lokasi">
      <div className="lokasi">
        <div className="location">
          <div id="lokasi" className="judul-lokasi">
            OUR LOCATION
          </div>
          <div className="img-lokasi"></div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
