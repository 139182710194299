import ReactPlayer from "react-player";
import "./video.scss";

function LocalVideo() {
  const videoSrc = "videos/videoss.mp4";

  return (
    <div className="vidoe">
      <div className="a">
        <ReactPlayer
          url={videoSrc}
          controls={true}
          playing={true}
          muted={true}
        />
      </div>
    </div>
  );
}

export default LocalVideo;
