import React from "react";
import "./footer.scss";
import logo from "../../media/logo/logo-bukit-podomoro.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-Bukit-Podomoro-Jakarta"
          />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <p>
              Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13960,
              Indonesia.
            </p>
          </div>
          <div className="contact">Contact us : +6285121010886 - Nita</div>
          <div className="privasi">
            <div> Privacy Policies</div>
          </div>
        </div>
      </div>
      <div className="container-bawah">BUKIT PODOMORO JAKARTA</div>
    </div>
  );
};

export default footer;
