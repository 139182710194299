import React, { useState } from "react";
import "./ruko.scss";
import uspimages from "../../media/produk/ruko/usp/usp.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logoruko from "../../media/logo/logo ruko.png";
import avenueimages from "../../media/produk/ruko/rukoavenue.js";
import boulevradimages from "../../media/produk/ruko/rukoboulevard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faAlignJustify,
  faHouse,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import Gallerylogo from "./gallerylogo/gallerylogo.jsx";
import kokas from "../../media/logo/tenant/Kota_Kasablanka.svg.webp";
import smkg from "../../media/logo/tenant/smkg.webp";
import easy from "../../media/logo/tenant/Easy-Access.webp";
import kuda from "../../media/logo/tenant/pacuankuda.webp";

const Ruko = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
  };
  const [slider1Ref, setSlider1Ref] = useState(null);
  const [slider2Ref, setSlider2Ref] = useState(null);

  const slider1Settings = {
    slidesToShow: 1,
    swipeToSlide: true,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile
          swipeToSlide: true,
        },
      },
    ],
  };

  const slider2Settings = {
    slidesToShow: 5,
    swipeToSlide: true,
    focusOnSelect: true,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 4, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,
          arrows: false,
        },
      },
    ], // Optional: Add focus to the selected slide in slider 2
  };
  const avenuewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20ini%20(ruko+avenue)%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const boulevardwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20ini%20(ruko+boulevard)%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-ruko">
      <div className="ruko">
        <div className="header-ruko">
          <div id="ruko" className="judul-ruko">
            OUR COMMERCIAL DISTRICT
          </div>
          <div className="sub-judul-ruko">
            Ruko Premium di Kawasan Premium Jakarta
          </div>
        </div>
        <div className="galery-ruko">
          <div className="kartu">
            <Slider
              {...slider1Settings}
              asNavFor={slider2Ref}
              ref={setSlider1Ref}
            >
              {uspimages.map((image, index) => (
                <div key={index}>
                  <img className="kartugambar1" src={image} alt="Podomoro" />
                </div>
              ))}
            </Slider>
            <Slider
              {...slider2Settings}
              asNavFor={slider1Ref}
              ref={setSlider2Ref}
            >
              {uspimages.map((image, index) => (
                <div key={index}>
                  <img className="kartugambar2" src={image} alt="Podomoro" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="container-card-ruko">
          <div className="logo-business">
            <img className="logoruko" src={logoruko} alt="logo-ruko" />
            <div className="desk">
              Pertama dan satu-satunya kawasan ruko(Commercial Area) terlengkap
              dan termewah di Kota Jakarta
            </div>
          </div>
          <div className="cardruko">
            <div className="carousel-ruko-bukit">
              <div className="kartu">
                <Slider {...settings}>
                  {boulevradimages.map((image, index) => (
                    <div>
                      <img
                        className="kartugambar0"
                        key={index}
                        src={image}
                        alt="Podomoro"
                      />
                    </div>
                  ))}
                </Slider>
                <div className="container-title-card">
                  <div className="titlecard">
                    <div className="judul-rumah2">Ruko Bukit Boulevard</div>
                  </div>
                  <div className="cicilan">
                    <div className="angka">18</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
                <hr color="black" />
                <div className="gridspek">
                  <FontAwesomeIcon icon={faMaximize} />
                  <span> : 50m²</span>
                  <FontAwesomeIcon icon={faHouse} />
                  <span> : 150m²</span>
                  <FontAwesomeIcon icon={faAlignJustify} />
                  <span>: 4</span>
                  <FontAwesomeIcon icon={faShower} />
                  <span>: 4</span>
                </div>
                <div className="containerwhatsapp">
                  <button onClick={boulevardwa} className="whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
              </div>
            </div>
            <div className="carousel-ruko-bukit">
              <div className="kartu">
                <Slider {...settings}>
                  {avenueimages.map((image, index) => (
                    <div>
                      <img
                        className="kartugambar0"
                        key={index}
                        src={image}
                        alt="Podomoro"
                      />
                    </div>
                  ))}
                </Slider>
                <div className="container-title-card">
                  <div className="titlecard">
                    <div className="judul-rumah2">Ruko Bukit Avenue</div>
                  </div>
                  <div className="cicilan">
                    <div className="angka">31</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
                <hr color="black" />
                <div className="gridspek">
                  <FontAwesomeIcon icon={faMaximize} />
                  <span> : 85m²</span>
                  <FontAwesomeIcon icon={faHouse} />
                  <span> : 195m²</span>
                  <FontAwesomeIcon icon={faAlignJustify} />
                  <span>: 4</span>
                  <FontAwesomeIcon icon={faShower} />
                  <span>: 4</span>
                </div>
                <div className="containerwhatsapp">
                  <button onClick={avenuewa} className="whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery">
          <Gallerylogo />
        </div>
        <div className="surrounding-area">
          <div className="judul-surrounding"> Surrounding Area</div>
          <div className="surrounding">
            <div className="logo-row">
              <img className="foto" src={kokas} alt="" />
            </div>
            <div className="logo-row">
              <img className="foto" src={easy} alt="" />
            </div>
            <div className="logo-row">
              <img className="foto" src={smkg} alt="" />
            </div>
            <div className="logo-row">
              <img className="foto" src={kuda} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ruko;
