import React from "react";
import Fasilitasimages from "./fasilitas.js";
import "./fasilitas.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingColumns,
  faBus,
  faCartShopping,
  faCity,
  faHospital,
  faRoad,
  faSchool,
  faShop,
} from "@fortawesome/free-solid-svg-icons";

const fasilitas = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1,
          lazyload: true, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  return (
    <div className="container-fasilitas">
      <div className="fasilitas">
        <div id="fasilitas" className="judul-fasilitas">
          FASILITAS PREMIUM KAWASAN
        </div>
        <div className="carousel-fasilitas">
          <Slider {...settings}>
            {Fasilitasimages.map((image, index) => (
              <img
                className="kartugambar"
                key={index}
                src={image}
                alt={`Fasilitas ${index + 1}`}
              />
            ))}
          </Slider>
          <div className="list-fasilitas">
            <div className="list1">
              <div className="a1">
                <FontAwesomeIcon color="#b49c6c" size="2x" icon={faCity} />
              </div>
              <div className="judul-list">Central Business District</div>
              <div className="ket-list">
                <li>Mega Kuningan (25 menit)</li>
                <li>TB Simatupang (23 menit)</li>
                <li>SCBD Jakarta (34 menit)</li>
              </div>
              <div className="a1">
                <FontAwesomeIcon color="#b49c6c" size="2x" icon={faRoad} />
              </div>
              <div className="judul-list">Akses Jalan</div>
              <div className="ket-list">
                <li>Tol Becakayu (15 menit)</li>
                <li>Jakarta Inner Ring Road (15 menit)</li>
                <li>Jakarta Outer Ring Road (15 menit)</li>
              </div>
            </div>
            <div className="list1">
              <div className="a1">
                <FontAwesomeIcon color="#b49c6c" size="2x" icon={faBus} />
              </div>
              <div className="judul-list">Transportasi</div>
              <div className="ket-list">
                <li>Halte Busway (5 menit)</li>
                <li>Bandara Halim (15 menit)</li>
                <li>KRL Buaran (10 menit)</li>
                <li>LRT Jatibening (20 menit)</li>
              </div>
              <div className="a1">
                <FontAwesomeIcon
                  color="#b49c6c"
                  size="2x"
                  icon={faCartShopping}
                />
              </div>
              <div className="judul-list">Pusat Pembelanjaan</div>
              <div className="ket-list">
                <li>Mall Kelapa Gading (15 menit)</li>
                <li>Lotte Shopping Avenue (15 menit)</li>
                <li>Kota Kasablanka (15 menit)</li>
                <li>Plaza Indonesia (15 menit)</li>
              </div>
            </div>
            <div className="list1">
              <div className="a1">
                <FontAwesomeIcon color="#b49c6c" size="2x" icon={faShop} />
              </div>
              <div className="judul-list">Kuliner Terdekat</div>
              <div className="ket-list">
                <li>Area Buaran (6 menit)</li>
                <li>Area Rawamangun (6 menit)</li>
                <li>Area Tebet (6 menit)</li>
                <li>Area Kelapa Gading (15 menit)</li>
              </div>
              <div className="a1">
                <FontAwesomeIcon color="#b49c6c" size="2x" icon={faSchool} />
              </div>
              <div className="judul-list">Sekolah Internasional</div>
              <div className="ket-list">
                <li>Highscope Indonesia (24 menit)</li>
                <li>North Jakarta Intercultural School (24 menit)</li>
                <li>Singapore International School (24 menit)</li>
              </div>
            </div>
            <div className="list1">
              <div className="a1">
                <FontAwesomeIcon
                  color="#b49c6c"
                  size="2x"
                  icon={faBuildingColumns}
                />
              </div>
              <div className="judul-list">Universitas</div>
              <div className="ket-list">
                <li>i3L School of Business (19 menit)</li>
                <li>Swiss German University (24 menit)</li>
                <li>Universitas Indonesia (40 menit)</li>
              </div>
              <div className="a1">
                <FontAwesomeIcon color="#b49c6c" size="2x" icon={faHospital} />
              </div>
              <div className="judul-list">Rumah Sakit</div>
              <div className="ket-list">
                <li>RS Premier Jatinegara (15 menit)</li>
                <li>RS Columbia Asia (15 menit)</li>
                <li>OMNI Hospital (15 menit)</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default fasilitas;
