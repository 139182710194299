import Usp1 from "./usp (1).webp";
import Usp2 from "./usp (2).webp";
import Usp3 from "./usp (3).webp";
import Usp4 from "./usp (4).webp";
import Usp5 from "./usp (5).webp";
import Usp6 from "./usp (6).webp";
import Usp7 from "./usp (7).webp";
import Usp9 from "./usp (9).webp";
import Usp10 from "./usp (10).webp";
import Usp11 from "./usp (11).webp";
import Usp12 from "./usp (12).webp";
import Usp13 from "./usp (13).webp";
import Usp14 from "./usp (14).webp";
import Usp15 from "./usp (15).webp";
import Usp16 from "./usp (16).webp";
import Usp17 from "./usp (17).webp";
import Usp19 from "./usp (19).webp";
import Usp20 from "./usp (20).webp";
import Usp21 from "./usp (21).webp";
import Usp22 from "./usp (22).webp";
import Usp23 from "./usp (23).webp";

const uspimages = [
  Usp1,
  Usp2,
  Usp3,
  Usp4,
  Usp5,
  Usp6,
  Usp7,
  Usp9,
  Usp10,
  Usp11,
  Usp12,
  Usp13,
  Usp14,
  Usp15,
  Usp16,
  Usp17,
  Usp19,
  Usp20,
  Usp21,
  Usp22,
  Usp23,
];

export default uspimages;
