import Podomoro1 from "./podomoro (1).webp";
import Podomoro2 from "./podomoro (2).webp";
import Podomoro3 from "./podomoro (3).webp";
import Podomoro4 from "./podomoro (4).webp";
import Podomoro5 from "./podomoro (5).webp";
import Podomoro6 from "./podomoro (6).webp";
import Podomoro7 from "./podomoro (7).webp";
import Podomoro9 from "./podomoro (9).webp";
import Podomoro10 from "./podomoro (10).webp";
import Podomoro11 from "./podomoro (11).webp";
import Podomoro12 from "./podomoro (12).webp";
import Podomoro13 from "./podomoro (13).webp";

const homeimages = [
  Podomoro1,
  Podomoro2,
  Podomoro3,
  Podomoro4,
  Podomoro5,
  Podomoro6,
  Podomoro7,
  Podomoro9,
  Podomoro10,
  Podomoro11,
  Podomoro12,
  Podomoro13,
];

export default homeimages;
