import React from "react";
import "./navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imglogo from "../../media/logo/logo-bukit-podomoro.png";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const navbar = () => {
  const wanavbar = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-navbar">
      <div className="navbar">
        <div className="navbar-logo">
          <img className="img-logo" src={imglogo} alt="logo-bukitpodomoro" />
        </div>
        <div className="button-navbar">
          <button onClick={wanavbar} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Hubungi Kami
          </button>
        </div>
      </div>
    </div>
  );
};

export default navbar;
