import Tipe71 from "./7 (1).webp";
import Tipe72 from "./7 (2).webp";
import Tipe73 from "./7 (3).webp";
import Tipe74 from "./7cl.webp";
import Tipe75 from "./7 (4).webp";
import Tipe76 from "./7artdeco.png";
import Tipe77 from "./7 (5).webp";
import Tipe78 from "./7regent.webp";

const tipe7images = [
  Tipe71,
  Tipe72,
  Tipe73,
  Tipe74,
  Tipe75,
  Tipe76,
  Tipe77,
  Tipe78,
];

export default tipe7images;
