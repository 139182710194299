import Fasilitas1 from "../../media/fasilitas/fasilitas (1).webp";
import Fasilitas2 from "../../media/fasilitas/fasilitas (2).webp";
import Fasilitas3 from "../../media/fasilitas/fasilitas (3).webp";
import Fasilitas4 from "../../media/fasilitas/fasilitas (4).webp";
import Fasilitas5 from "../../media/fasilitas/fasilitas (5).webp";
import Fasilitas6 from "../../media/fasilitas/fasilitas (6).webp";
import Fasilitas7 from "../../media/fasilitas/fasilitas (7).webp";
import Fasilitas8 from "../../media/fasilitas/fasilitas (8).webp";
import Fasilitas9 from "../../media/fasilitas/fasilitas (9).webp";
import Fasilitas10 from "../../media/fasilitas/fasilitas (10).webp";
import Fasilitas11 from "../../media/fasilitas/fasilitas (11).webp";
import Fasilitas12 from "../../media/fasilitas/fasilitas (12).webp";
import Fasilitas13 from "../../media/fasilitas/fasilitas (13).webp";
import Fasilitas14 from "../../media/fasilitas/fasilitas (14).webp";

const fasilitasimages = [
  Fasilitas1,
  Fasilitas2,
  Fasilitas3,
  Fasilitas4,
  Fasilitas5,
  Fasilitas6,
  Fasilitas7,
  Fasilitas8,
  Fasilitas9,
  Fasilitas10,
  Fasilitas11,
  Fasilitas12,
  Fasilitas13,
  Fasilitas14,
];

export default fasilitasimages;
