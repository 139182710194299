import React from "react";
import "./about.scss";

const about = () => {
  return (
    <div className="container-about">
      <div className="about">
        <div className="judul-about">BUKIT PODOMORO JAKARTA</div>
        <div className="desk-about">
          Bukit Podomoro Jakarta adalah perumahan baru di Jakarta Timur di Jalan
          I Gusti Ngurah Rai. Bukit Podomoro Jakarta adalah perumahan yang
          dibangun oleh developer terkenal Agung Podomoro Land. Ini adalah rumah
          yang nyaman, premium, dan eksklusif. Konsep perumahan ini sangat
          mewah. Bukit Podomoro Jakarta terletak di tanah seluas 9,6 hektar di
          dekat Jakarta Pusat. Perumahan ini bebas banjir, lebih teduh, dan
          memiliki kualitas air dan udara yang lebih baik.
        </div>
      </div>
    </div>
  );
};

export default about;
