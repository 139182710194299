import Tenant1 from "./starbucks.webp";
import Tenant2 from "./UNIQLO_logo.svg.webp";
import Tenant3 from "./superindo.webp";
import Tenant4 from "./hokben.webp";
import Tenant5 from "./bakmigm.webp";
import Tenant6 from "./marugame.webp";
import Tenant7 from "./demodas.webp";
import Tenant9 from "./festive.webp";
import Tenant11 from "./Yoshinoya_logo.webp";

const tenantimages = [
  Tenant1,
  Tenant2,
  Tenant3,
  Tenant4,
  Tenant5,
  Tenant6,
  Tenant7,
  Tenant9,
  Tenant11,
];

export default tenantimages;
