import React from "react";
import "./rumah.scss";
import Rumahimages from "../../media/produk/home/home.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faBed,
  faHouse,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import tipe6images from "../../media/produk/tipe6.js";
import tipe7images from "../../media/produk/tipe7.js";
import tipe8images from "../../media/produk/tipe8.js";
import tipe9images from "../../media/produk/tipe9.js";

const rumah = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
  };
  const rumahwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20ini%20(rumah+bukit+podomoro)%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const tipe6 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20ini%20(tipe6)%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const tipe7 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20ini%20(tipe7)%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const tipe8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20ini%20(tipe8)%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const tipe9 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20ini%20(tipe9)%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="rumah">
      <div className="judul-rumah1">OUR HOME</div>
      <div id="rumah" className="container-parent">
        <div className="container">
          <div className="container-carousel-foto">
            <div className="kartu">
              <Slider {...settings}>
                {Rumahimages.map((image, index) => (
                  <div>
                    <img
                      className="kartugambar1"
                      key={index}
                      src={image}
                      alt="Podomoro"
                    />
                  </div>
                ))}
              </Slider>
              <div className="container-title-card">
                <div className="titlecard">
                  <div className="judul-rumah2">Bukit Podomoro Jakarta</div>
                  <span>Free Furnished</span>
                </div>
                <div className="cicilan">
                  <div className="angka">20</div>
                  <div className="ket">Juta/Bulan</div>
                </div>
              </div>
              <hr color="black" />
              <div className="gridspek">
                <FontAwesomeIcon icon={faMaximize} />
                <span> : 136m²</span>
                <FontAwesomeIcon icon={faHouse} />
                <span> : 231m²</span>
                <FontAwesomeIcon icon={faBed} />
                <span>: 4+1</span>
                <FontAwesomeIcon icon={faShower} />
                <span>: 4+1</span>
              </div>
              <div className="containerwhatsapp">
                <button onClick={rumahwa} className="whatsapp">
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
              </div>
            </div>
          </div>
          <div className="container-all-card">
            <div className="container-card1">
              <div className="card">
                <Slider {...settings}>
                  {tipe6images.map((image, index) => (
                    <div>
                      <img
                        className="kartugambar1"
                        key={index}
                        src={image}
                        alt="Podomoro"
                      />
                    </div>
                  ))}
                </Slider>
                <div className="container-title-card">
                  <div className="titlecard">
                    <div className="judul-rumah">Tipe 6 Classic</div>
                  </div>
                </div>
                <hr color="black" />
                <div className="gridspek">
                  <FontAwesomeIcon icon={faMaximize} />
                  <span> : 90m²</span>
                  <FontAwesomeIcon icon={faHouse} />
                  <span> : 146m²</span>
                  <FontAwesomeIcon icon={faBed} />
                  <span>: 3+1</span>
                  <FontAwesomeIcon icon={faShower} />
                  <span>: 3+1</span>
                </div>
                <div className="containerwhatsapp">
                  <button onClick={tipe6} className="whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
              </div>
              <div className="card">
                <Slider {...settings}>
                  {tipe7images.map((image, index) => (
                    <div>
                      <img
                        className="kartugambar1"
                        key={index}
                        src={image}
                        alt="Podomoro"
                      />
                    </div>
                  ))}
                </Slider>
                <div className="container-title-card">
                  <div className="titlecard">
                    <div className="judul-rumah">Tipe 7</div>
                  </div>
                </div>
                <hr color="black" />
                <div className="gridspek">
                  <FontAwesomeIcon icon={faMaximize} />
                  <span> : 105m²</span>
                  <FontAwesomeIcon icon={faHouse} />
                  <span> : 193m²</span>
                  <FontAwesomeIcon icon={faBed} />
                  <span>: 4</span>
                  <FontAwesomeIcon icon={faShower} />
                  <span>: 4</span>
                </div>
                <div className="containerwhatsapp">
                  <button onClick={tipe7} className="whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
              </div>
            </div>
            <div className="container-card2">
              <div className="card">
                <Slider {...settings}>
                  {tipe8images.map((image, index) => (
                    <div>
                      <img
                        className="kartugambar1"
                        key={index}
                        src={image}
                        alt="Podomoro"
                      />
                    </div>
                  ))}
                </Slider>
                <div className="container-title-card">
                  <div className="titlecard">
                    <div className="judul-rumah">Tipe 8</div>
                  </div>
                </div>
                <hr color="black" />
                <div className="gridspek">
                  <FontAwesomeIcon icon={faMaximize} />
                  <span> : 120m²</span>
                  <FontAwesomeIcon icon={faHouse} />
                  <span> : 223m²</span>
                  <FontAwesomeIcon icon={faBed} />
                  <span>: 5</span>
                  <FontAwesomeIcon icon={faShower} />
                  <span>: 5</span>
                </div>
                <div className="containerwhatsapp">
                  <button onClick={tipe8} className="whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
              </div>
              <div className="card">
                <Slider {...settings}>
                  {tipe9images.map((image, index) => (
                    <div>
                      <img
                        className="kartugambar1"
                        key={index}
                        src={image}
                        alt="Podomoro"
                      />
                    </div>
                  ))}
                </Slider>
                <div className="container-title-card">
                  <div className="titlecard">
                    <div className="judul-rumah">Tipe 9 Luxury</div>
                  </div>
                </div>
                <hr color="black" />
                <div className="gridspek">
                  <FontAwesomeIcon icon={faMaximize} />
                  <span> : 117m²</span>
                  <FontAwesomeIcon icon={faHouse} />
                  <span> : 197m²</span>
                  <FontAwesomeIcon icon={faBed} />
                  <span>: 5</span>
                  <FontAwesomeIcon icon={faShower} />
                  <span>: 5</span>
                </div>
                <div className="containerwhatsapp">
                  <button onClick={tipe9} className="whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default rumah;
